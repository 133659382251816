import { createReducer } from '@reduxjs/toolkit';
import { setUserSettings } from '../../actions/userSettingsActions';

const initialState = {
  filters: [],
  tablePageSize: 10,
  displayColumns: [],
};
const userSettingsReducer = createReducer(initialState, builder => {
  builder.addCase(setUserSettings, (state, action) => ({ ...state, ...action.payload })).addDefaultCase(state => state);
});

export default userSettingsReducer;
