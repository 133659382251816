import { useEffect } from 'react';
import { identify, init } from '@fullstory/browser';

const CIMPRESS_FULLSTORY_ORG_ID = '97CT5';

const FullStory = ({ isProduction, profile: { sub, name, email } }) => {
  useEffect(() => {
    init({ orgId: CIMPRESS_FULLSTORY_ORG_ID, devMode: !isProduction });
  }, [isProduction]);

  useEffect(() => {
    if (sub) identify(sub, { displayName: name, email: email });
  }, [sub, name, email]);

  return null;
};

export default FullStory;
