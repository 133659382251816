import { shapes } from '@cimpress/react-components';

const { Robot } = shapes;

const RobotMessage = ({ message = 'No Data', status = 'warning', style }) => (
  <div className={'flex-column'} style={{ alignItems: 'center', ...style }}>
    <Robot status={status} />
    <h4>{message}</h4>
  </div>
);

export default RobotMessage;
