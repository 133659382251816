import auth from '../../services/auth';
import { getProductLink } from '../../services/productLink';
import { ProductLinkViewer } from '@cimpress-technology/products-product-link-editor';
import { useEffect, useState } from 'react';
import { PRODUCT_LINK_ENV } from '../../constants/productLinkerEnv';
import { useDispatch } from 'react-redux';
import { Accordion } from '@cimpress/react-components';
import { showSnackbar } from '../../redux/actions/snackbarActions';
import Loading from './Loading';

function ProductLinkViewerWrapper({ productLinkHref, accountId }) {
  const [productLink, setProductLink] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProductLink = async () => {
      setIsLoading(true);
      try {
        const data = await getProductLink({ url: productLinkHref });
        setProductLink(data);
      } catch (error) {
        dispatch(showSnackbar({ message: `Failed to fetch Product Link: ${JSON.stringify(error)}`, style: 'danger' }));
      } finally {
        setIsLoading(false);
      }
    };
    fetchProductLink();
  }, [productLinkHref, dispatch]);

  return (
    <>
      <Accordion title={isLoading ? 'Loading...' : productLink?.name} bodyStyle={{ padding: '16px' }}>
        {isLoading ? (
          <Loading />
        ) : (
          <ProductLinkViewer
            selectedProductLink={productLink}
            accessToken={auth.getAccessToken()}
            account={accountId}
            environment={PRODUCT_LINK_ENV.PRD}
            viewInCard={false}
          />
        )}
      </Accordion>
    </>
  );
}

export default ProductLinkViewerWrapper;
