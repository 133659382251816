import { all } from 'redux-saga/effects';
import { watchAccountDetailsSagas } from './accountDetailsSaga';
import { watchProductionDelaySagas } from './productionDelaySaga';

// Root generator function that runs all the sagas
export default function* rootSaga() {
  yield all([
    watchProductionDelaySagas(),
    watchAccountDetailsSagas(),
    // add more sagas here.
  ]);
}
