import { getData } from './http';

/**
 *
 * @param {string} principal cannonical id of user
 * @param {string} resourceType coam resource type
 * @param {string} resourceId coam resource identifier
 * @param {string} permission coam permission
 * @returns
 */
export const searchPrincipalPermissions = ({ principal, resourceType, resourceId, permission, queryParams = {} }) => {
  // to avoid any contribution to coam outage
  if (!principal && !resourceType) throw new Error(`400 - COAM can't be called without cannonicalId and resourceType`);
  const url = [`${process.env.REACT_APP_COAM_BASE_URL}principals/${principal}/permissions/${resourceType}`];
  if (resourceId) {
    url.push(resourceId);
  }
  if (permission) {
    url.push(permission);
  }
  return getData({ url: url.join('/'), queryParams: { ...queryParams, cacheBuster: Date.now() } });
};
