import { Select } from '@cimpress/react-components';
import DatePicker from '@cimpress/react-components/lib/DatePicker';
import { useEffect, useState } from 'react';
import { getLocationsAndFulfillersData } from '../../../services/fulfillers';
import LocationDetailsCard from './LocationDetailsCard';
import { showSnackbar } from '../../../redux/actions/snackbarActions';
import { useDispatch } from 'react-redux';

const DelayUntilSection = ({ schedule = {}, setSchedule, disabled = false }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationDetails, setLocationDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchLocationsAndFulfillers = async () => {
      setIsLoading(true);
      try {
        const locationList = await getLocationsAndFulfillersData();
        setLocationDetails(locationList);
      } catch (error) {
        dispatch(
          showSnackbar({
            message: `Failed to fetch Fulfiller / Locations : ${JSON.stringify(error)}`,
            style: 'danger',
          }),
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchLocationsAndFulfillers();
  }, [dispatch]);

  const handleOptionChange = selectedLocation => {
    setSelectedLocation(selectedLocation);
    if (selectedLocation) {
      setSchedule(schedule => ({
        ...schedule,
        fulfillmentLocationId: selectedLocation.value?.fulfillmentLocationId,
      }));
    }
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <div className='flex-column'>
        <div>
          <p style={{ marginBottom: '16px' }}>Setup the date and time until which the production will be on hold</p>
          <DatePicker
            label='Delay Until Date'
            dateFormat='Y-MM-DD'
            value={schedule.delayUntil}
            closeOnClickOutside={true}
            closeOnSelect={true}
            isClearable={!disabled}
            onChange={d => setSchedule({ ...schedule, delayUntil: d ? new Date(d).toISOString() : undefined })}
            required
            disabled={disabled}
          />
        </div>
        <p style={{ marginBottom: '16px' }}>Orders received in below time and date range will be on hold</p>
        <div className='flex-row-wrap'>
          <div style={{ marginRight: '16px' }}>
            <DatePicker
              label='Start'
              dateFormat='Y-MM-DD'
              value={schedule.startDate}
              closeOnClickOutside={true}
              closeOnSelect={true}
              isClearable={!disabled}
              onChange={d => setSchedule({ ...schedule, startDate: d ? new Date(d).toISOString() : undefined })}
              required
              disabled={disabled}
            />
          </div>
          <div>
            <DatePicker
              label='End'
              dateFormat='Y-MM-DD'
              value={schedule.endDate}
              closeOnClickOutside={true}
              closeOnSelect={true}
              isClearable={!disabled}
              onChange={d => setSchedule({ ...schedule, endDate: d ? new Date(d).toISOString() : undefined })}
              required
              disabled={disabled}
            />
          </div>
        </div>
        <div style={{ width: '620px' }}>
          <Select
            isLoading={isLoading}
            isClearable
            label='Select a location'
            value={selectedLocation}
            options={locationDetails?.map(({ fulfillerName, location, fulfillmentLocationId }) => ({
              value: { fulfillerName, location, fulfillmentLocationId },
              label: `${fulfillerName} - ${location}(${fulfillmentLocationId})`,
            }))}
            onChange={handleOptionChange}
          />
          {selectedLocation && <LocationDetailsCard selectedLocation={selectedLocation} />}
        </div>
      </div>
    </div>
  );
};

export default DelayUntilSection;
