import './ProductionDelayList.css';
import { useEffect, useMemo, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from '@cimpress/react-components';
import { IconBin, IconView, IconPencilAlt } from '@cimpress-technology/react-streamline-icons';
import ListTable from '../common/ListTable';
import Filters from '../common/Filters';
import ProductionDelaySearchBar from './ProductionDelaySearchBar';
import auth from '../../services/auth';
import { DELAY_SCHEDULE_STATUS } from '../../constants/delaySchedule';
import { CANONICAL_ID_KEY } from '../../constants/common';
import { CREATED_BY_FILTER, FILTER_NAMES, SEARCH_FIELDS } from '../../constants/filters';
import { getScheduleStatus, filterOrchestratedProductionDelays } from '../../services/serviceHelpers';
import ProductionDelayDetailsDrawer from '../ProductionDelayDetails/ProductionDelayDetailsDrawer';
import { useDispatch } from 'react-redux';
import { getProductionDelays } from '../../redux/actions/productionDelayActions';
import DeleteProductionDelayModal from '../common/DeleteProductionDelayModal';
import { setUserSettings } from '../../redux/actions/userSettingsActions';

const ProductionDelayList = ({ productionDelayStore, userSettings, permissions }) => {
  const [productionDelayList, setProductionDelayList] = useState([]);
  const [productionDelayMasterList, setProductionDelayMasterList] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(userSettings.filters);
  const [displayColumns, setDisplayColumns] = useState(userSettings.displayColumns);
  const [productionDelayObj, setProductionDelayObj] = useState();
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
  const [tempSearchText, setTempSearchText] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const appliedSearchText = useRef('');
  const dispatch = useDispatch();

  useEffect(() => {
    // Apart from ProductionDelayList page all the other pages have grey as their background color
    // This is a workaround solution to tackle the current scenario
    document.body.style.backgroundColor = 'white';
    return () => {
      document.body.style.backgroundColor = '#eff3f5';
    };
  }, []);

  useEffect(() => {
    dispatch(getProductionDelays());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: 'Production Delay Name',
        Cell: ({ original }) => (
          <Button
            variant='anchor'
            onClick={() => {
              setOpenDetailsDrawer(true);
              setProductionDelayObj(original);
            }}
          >
            {original.name}
          </Button>
        ),
        minWidth: 200,
      },
      { Header: 'Delay Schedules', accessor: 'scheduleCount' },
      { Header: 'Product Links', accessor: 'productLinksCount' },
      { Header: 'Created At', accessor: 'createdAt', minWidth: 180 },
      { Header: 'Created By', accessor: 'createdBy', minWidth: 240 },
      { Header: 'Modified At', accessor: 'modifiedAt', minWidth: 180 },
      { Header: 'Modified By', accessor: 'modifiedBy', minWidth: 240 },
      {
        Header: 'Actions',
        minWidth: 120,
        Cell: ({ original }) => (
          <div>
            <Tooltip contents={'View Details'} style={{ marginRight: '12px' }}>
              <Link to={`/${original.id}`}>
                <IconView size={'lg'} weight={'fill'} />
              </Link>
            </Tooltip>
            <Tooltip contents={'Edit'} style={{ marginRight: '12px' }}>
              <Link to={`/${original.id}/edit`}>
                <IconPencilAlt size={'lg'} weight={'fill'} />
              </Link>
            </Tooltip>
            <Tooltip contents={'Delete'}>
              <Button
                variant='anchor'
                onClick={() => {
                  setProductionDelayObj(original);
                  setOpenDeleteModal(true);
                }}
              >
                <IconBin size={'lg'} weight={'fill'} />
              </Button>
            </Tooltip>
          </div>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    // destructure & decide what to receive into the table from the [ productionDelayOrchestrated data = (productionDelay + associated productLinks) ]
    const initialList = productionDelayStore.data.map(
      ({ id, name, delaySchedule = [], productLinks = [], createdAt, createdBy, modifiedAt, modifiedBy }) => ({
        id,
        name,
        delaySchedule: delaySchedule.map(({ startDate, endDate, ...restOfFields }) => ({
          startDate,
          endDate,
          ...restOfFields,
          status: getScheduleStatus({ startDate, endDate }),
        })),
        scheduleCount: delaySchedule.length,
        productLinks,
        productLinksCount: productLinks.length,
        createdAt: createdAt ? new Date(createdAt).toLocaleString() : null,
        createdBy,
        modifiedAt: modifiedAt ? new Date(modifiedAt).toLocaleString() : null,
        modifiedBy,
        // selected flag will determine whether to show this row as selected when this is past in row data
        selected: false,
      }),
    );
    setProductionDelayMasterList(initialList);
    setTempSearchText('');
  }, [productionDelayStore]);

  useEffect(() => {
    dispatch(setUserSettings({ filters: appliedFilters }));
    setProductionDelayList(currentProductionDelayList =>
      filterOrchestratedProductionDelays({
        masterData: productionDelayMasterList,
        currentData: currentProductionDelayList,
        filtersToApply: appliedFilters,
        searchToApply: {
          searchText: appliedSearchText.current,
          searchFields: SEARCH_FIELDS,
        },
      }),
    );
  }, [productionDelayMasterList, appliedFilters, dispatch]);

  useEffect(() => {
    if (!userSettings.displayColumns.length) {
      const newDisplayColumns = columns.reduce((acc, { Header }) => {
        if (Header) acc.push(Header);
        return acc;
      }, []);
      setDisplayColumns(newDisplayColumns);
      dispatch(setUserSettings(newDisplayColumns));
    }
  }, [dispatch, userSettings.displayColumns.length, columns]);

  useEffect(() => {
    dispatch(setUserSettings({ displayColumns }));
  }, [dispatch, displayColumns]);

  // If the user enters something in search bar and doesn't press search button then it may be misleading if we let the string stay.
  // So if anything on the screen triggers a rerender then we would want to reset the tempSearchString to last searched value.
  // This is to give user an exact idea of what filtered data is visible to them
  // Please add more state variables if added to this component
  useEffect(() => {
    setTempSearchText(appliedSearchText.current);
  }, [productionDelayList, displayColumns, productionDelayMasterList, appliedFilters, openDetailsDrawer]);

  return (
    <div className={'flex-row'} style={{ alignItems: 'flex-start' }}>
      <div className={'flex-column production-delay-filters-section'}>
        <Filters
          appliedFilters={appliedFilters}
          filterList={[
            {
              name: FILTER_NAMES.STATUS,
              options: Object.entries(DELAY_SCHEDULE_STATUS)
                .map(([key, value]) => ({ label: value, value }))
                .sort(({ label: label1 }, { label: label2 }) => (label1 <= label2 ? -1 : 1)),
            },
            {
              name: FILTER_NAMES.CREATED_BY,
              options: [
                {
                  label: `${CREATED_BY_FILTER.ME} (${auth.getProfile()?.name})`,
                  value: auth.getProfile()?.[CANONICAL_ID_KEY],
                },
                { label: CREATED_BY_FILTER.OTHERS, value: CREATED_BY_FILTER.OTHERS },
              ],
            },
            // TODO: Try implementing other type of Filters - like ones that make API call
            // also extend PropTypes for Filters when implemented
            // {
            //   name: 'Custom Element',
            //   element: <div>My Custom React Element</div>,
            // },
          ]}
          onChange={({ data }) => {
            setAppliedFilters(data);
          }}
        />
      </div>
      <div className={'flex-column production-delay-list-table-section'}>
        <ProductionDelaySearchBar
          searchText={tempSearchText}
          permissions={permissions}
          onChange={({ data }) => {
            setTempSearchText(data);
          }}
          onSearch={({ data }) => {
            setTempSearchText(data);
            appliedSearchText.current = data;
            setProductionDelayList(currentProductionDelayList =>
              filterOrchestratedProductionDelays({
                masterData: productionDelayMasterList,
                currentData: currentProductionDelayList,
                filtersToApply: appliedFilters,
                searchToApply: {
                  searchText: appliedSearchText.current,
                  searchFields: SEARCH_FIELDS,
                },
              }),
            );
          }}
        />
        <ListTable
          loading={productionDelayStore.isLoading}
          columns={columns}
          data={productionDelayList}
          selectable={false}
          actions={[
            {
              label: 'Delete',
              onClick: payload => {
                console.log(JSON.stringify(payload, null, 2));
              },
            },
          ]}
          // is called whenever row checkbox or selectAll checkbox is clicked
          onRowSelection={({ data }) => {
            console.log(JSON.stringify({ data }, null, 2));
            setProductionDelayList(previousList =>
              previousList?.map(row => {
                const selectedRow = data.find(dataRow => row.id === dataRow.id);
                if (selectedRow) return selectedRow;
                return row;
              }),
            );
          }}
          displayColumns={displayColumns}
          displayColumnOptions={columns.reduce((acc, { Header }) => {
            if (Header && Header !== 'Actions') acc.push(Header);
            return acc;
          }, [])}
          onChangeDisplayColumns={({ data }) => {
            setDisplayColumns(data);
          }} // to be called on every check/uncheck of DisplayColumns dropdown
        />
      </div>
      {!openDetailsDrawer ? null : (
        <ProductionDelayDetailsDrawer
          open={openDetailsDrawer}
          onClose={() => setOpenDetailsDrawer(false)}
          productionDelay={productionDelayObj}
          principal={auth.getProfile()?.[CANONICAL_ID_KEY]}
        />
      )}
      <DeleteProductionDelayModal
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
        productionDelay={productionDelayObj}
        onSuccessfulDeletion={() => {
          setProductionDelayMasterList(productionDelayMasterList.filter(item => item.id !== productionDelayObj.id));
          setProductionDelayList(productionDelayList.filter(item => item.id !== productionDelayObj.id));
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ productionDelay, userSettings }) => ({
  productionDelayStore: productionDelay,
  userSettings,
});

export default connect(mapStateToProps)(ProductionDelayList);
