import './Shell.css';
import { Link } from 'react-router-dom';
import Header from '@cimpress-technology/react-platform-header';
import { Snackbar } from '@cimpress/react-components';
import { hideSnackbar } from '../../redux/actions/snackbarActions';
import CssLoader from '@cimpress/react-components/lib/CssLoader';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getAccountDetails } from '../../redux/actions/accountDetailsActions';
import auth from '../../services/auth';

function Shell({ children, delay, style, message, showSnackbar }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccountDetails());
  }, [dispatch]);

  return (
    <CssLoader>
      <Snackbar show={showSnackbar} status={style} delay={delay} onHideSnackbar={() => dispatch(hideSnackbar())}>
        {message}
      </Snackbar>
      <Header
        responsive
        accessToken={auth.getAccessToken()}
        appTitle={
          <Link className={'navTitle'} to={'/'}>
            Production Delay
          </Link>
        }
        profile={auth.getProfile()}
        onLogInClicked={() => auth.login()}
        onLogOutClicked={() => auth.logout('/')}
        isLoggedIn={auth.isLoggedIn()}
      />
      <div className='root-child'>{children}</div>
    </CssLoader>
  );
}

const mapStateToProps = ({ snackbar: { showSnackbar, delay, style, message } }) => ({
  showSnackbar,
  delay,
  style,
  message,
});
export default connect(mapStateToProps)(Shell);
