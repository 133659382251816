export const FILTER_NAMES = {
  STATUS: 'Status',
  CREATED_BY: 'Created By',
};

export const CREATED_BY_FILTER = {
  ME: 'Me',
  OTHERS: 'Others',
};

export const SEARCH_FILTER_NAME = 'Search Text';

// curated productionDelay data fields that need to be searched against
export const SEARCH_FIELDS = ['name', 'createdBy', 'modifiedBy'];
