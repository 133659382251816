import { put, all, call, takeLatest } from 'redux-saga/effects';
import { getProductionDelays } from '../../services/productionDelay';
import { getProductLinks } from '../../services/productLink';
import auth from '../../services/auth';
import { orchestrateProductionDelayData } from '../../services/serviceHelpers';
import {
  getProductionDelaysSuccess,
  getProductionDelaysFailure,
  GET_PRODUCTION_DELAYS,
} from '../actions/productionDelayActions';
import { showSnackbar } from '../actions/snackbarActions';
import { ACCOUNT_ID_KEY } from '../../constants/common';

// Generator function that dispatches an GET_PRODUCTION_DELAY_SUCCESS action with data fetched from a server
export function* getProductionDelaySaga() {
  const accountId = auth.getProfile()?.[ACCOUNT_ID_KEY];
  try {
    const [
      {
        _embedded: { item: productionDelays = [] },
      },
      {
        _embedded: { item: productLinks = [] },
      },
    ] = yield all([
      call(() => getProductionDelays({ accountId, sort: 'createdAt:desc', limit: 200 })),
      call(() =>
        getProductLinks({ accountId, relatedResourceType: process.env.REACT_APP_PRODUCTION_DELAY_RESOURCE_TYPE }),
      ),
    ]);
    // send orchestrated data to store
    yield put(getProductionDelaysSuccess(orchestrateProductionDelayData({ productionDelays, productLinks })));
  } catch (error) {
    console.error('Error fetching production delays:', JSON.stringify(error));
    yield put(getProductionDelaysFailure());
    yield put(showSnackbar({ message: `Failed to fetch Product Link: ${JSON.stringify(error)}`, style: 'danger' }));
  }
}

// Generator function that watches for all actions and calls the appropriate generator function
export function* watchProductionDelaySagas() {
  yield takeLatest(GET_PRODUCTION_DELAYS, getProductionDelaySaga);
  // make another action + saga entry here
}
