import { IconInformationCircle } from '@cimpress-technology/react-streamline-icons';
import { Tooltip } from '@cimpress/react-components';
import { info } from '@cimpress/react-components/lib/colors';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSnackbar } from '../../redux/actions/snackbarActions';
import { getProductLink } from '../../services/productLink';
import Loading from '../common/Loading';
import './ProductLinkList.css';
import P from 'bluebird';
import RobotMessage from '../common/RobotMessage';

function ProductLinkList({ productionDelay }) {
  const [productLinks, setProductLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProductLinks = async () => {
      if (productionDelay) {
        setIsLoading(true);
        try {
          const data = await P.map(productionDelay.productLinks, link => getProductLink({ url: link }), {
            concurrency: 5,
          });
          setProductLinks(data);
        } catch (error) {
          dispatch(
            showSnackbar({ message: `Failed to fetch Product Links: ${JSON.stringify(error)}`, style: 'danger' }),
          );
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchProductLinks();
  }, [productionDelay, dispatch]);
  return (
    <>
      <div style={{ marginTop: '32px' }}>
        <span className={'pd-drawer-heading'}>Impacted Products </span>
        <span style={{ textTransform: 'none' }} className={'pd-drawer-heading'}>
          ({productLinks.length} product groups)
        </span>
        <Tooltip
          contents={
            'Associated product groups that contain the impacted products whose production time will be extended with the help of this delay schedule'
          }
          direction='right'
        >
          <IconInformationCircle
            weight={'fill'}
            color={info.darker}
            style={{ verticalAlign: 'middle', marginLeft: '4px' }}
          />
        </Tooltip>
      </div>
      {isLoading ? (
        <Loading />
      ) : productLinks?.length ? (
        <>
          {productLinks?.map(item => {
            return (
              <Link key={item.id} to={`/${productionDelay.id}`} className='product-links'>
                {item.name}
              </Link>
            );
          })}
        </>
      ) : (
        <RobotMessage message={'No Product Groups Linked'} />
      )}
    </>
  );
}

export default ProductLinkList;
