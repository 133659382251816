import { deleteProductionDelay } from '../../services/productionDelay';
import { showSnackbar } from '../../redux/actions/snackbarActions';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { getProductLinks } from '../../services/productLink';
import { Button, Modal } from '@cimpress/react-components';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import Loading from './Loading';

function DeleteProductionDelayModal({ openModal, setOpenModal, productionDelay, onSuccessfulDeletion, accountId }) {
  const [isLoadingProductLinks, setIsLoadingProductLinks] = useState(false);
  const [isDeletionAllowed, setIsDeletionAllowed] = useState();
  const [isDeleting, setIsDeleting] = useState();
  const { pathname } = useLocation();
  const showReviewButton = matchPath(pathname, { path: '/(index.html)?', exact: true })?.isExact;
  const dispatch = useDispatch();

  const deleteProductionDelayHandler = async () => {
    try {
      setIsDeleting(true);
      await deleteProductionDelay(productionDelay?.id);
      onSuccessfulDeletion();
      dispatch(showSnackbar({ message: 'Production Delay deleted successfully', style: 'success', delay: 5000 }));
    } catch (error) {
      dispatch(
        showSnackbar({ message: `Failed to delete Production Delay: ${JSON.stringify(error)}`, style: 'danger' }),
      );
    } finally {
      setIsDeleting(false);
      setOpenModal(false);
    }
  };

  useEffect(() => {
    const checkIfDeletionAllowed = async productionDelayHref => {
      try {
        setIsLoadingProductLinks(true);
        const {
          _embedded: { item: productLinksData },
        } = await getProductLinks({
          accountId: accountId,
          relatedResourceType: process.env.REACT_APP_PRODUCTION_DELAY_RESOURCE_TYPE,
          relatedResourceHref: productionDelayHref,
        });
        setIsDeletionAllowed(productLinksData.length === 0);
      } catch (error) {
        dispatch(showSnackbar({ message: `Failed to fetch Product Links: ${JSON.stringify(error)}`, style: 'danger' }));
        setOpenModal(false);
      } finally {
        setIsLoadingProductLinks(false);
      }
    };

    if (openModal) {
      checkIfDeletionAllowed(`${process.env.REACT_APP_PRODUCTION_DELAY_BASE_URL_V1}/${productionDelay?.id}`);
    }
  }, [productionDelay?.id, accountId, dispatch, setOpenModal, openModal]);

  return (
    <>
      <Modal
        status='danger'
        show={openModal}
        onRequestHide={() => setOpenModal(false)}
        title='Delete Production Delay ?'
        closeButton={!isDeleting}
        footer={
          isLoadingProductLinks || isDeleting ? null : (
            <>
              <Button onClick={() => setOpenModal(false)}>Cancel</Button>
              {isDeletionAllowed ? (
                <Button variant='primary' onClick={deleteProductionDelayHandler} style={{ marginLeft: '16px' }}>
                  Confirm
                </Button>
              ) : showReviewButton ? (
                <Link to={`/${productionDelay?.id}/edit`} style={{ marginLeft: '16px' }}>
                  <Button variant='primary'>Review impacted products</Button>
                </Link>
              ) : null}
            </>
          )
        }
      >
        {isLoadingProductLinks || isDeleting ? (
          <Loading />
        ) : (
          <p>
            {isDeletionAllowed
              ? `Are you sure you want to delete "${productionDelay?.name}"? This is an irreversible process and all the schedules within this PTD will be delete.`
              : 'There are product groups attached to this delay, please remove all product groups before deleting the production delay.'}
          </p>
        )}
      </Modal>
    </>
  );
}

const mapStateToProps = ({
  accountDetails: {
    data: { accountId },
  },
}) => ({
  accountId,
});
export default connect(mapStateToProps)(DeleteProductionDelayModal);
