import Spinner from '@cimpress/react-components/lib/shapes/Spinner';

const Loading = ({ size, style }) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...style,
    }}
  >
    <Spinner size={size} />
  </div>
);

export default Loading;
