import './ListTable.css';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactTablev6, Checkbox, Dropdown, Button } from '@cimpress/react-components';
import { info } from '@cimpress/react-components/lib/colors';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUserSettings } from '../../../redux/actions/userSettingsActions';

const ListTable = ({
  loading,
  columns = [],
  data = [],
  selectable,
  actions,
  onRowSelection,
  displayColumns,
  displayColumnOptions,
  onChangeDisplayColumns,
  defaultPageSize,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [rowSelection, setRowSelection] = useState(Array(data.length).fill(false));
  const [currentColumns, setCurrentColumns] = useState(columns);
  const [currentDisplayColumns, setCurrentDisplayColumns] = useState(displayColumns);
  const [currentDisplayColumnOptions, setCurrentDisplayColumnOptions] = useState(displayColumnOptions);
  const [currentData, setCurrentData] = useState(data);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectAll(data?.length && data.every(({ selected }) => selected === true));
    setRowSelection(data.map(({ selected }) => selected || false));
    setCurrentColumns(columns);
    setCurrentData(data);
    const columnList = columns.map(({ Header }) => Header).filter(Header => !!Header);
    setCurrentDisplayColumns(displayColumns || columnList);
    setCurrentDisplayColumnOptions(displayColumnOptions || columnList);
  }, [data.length, columns, data, displayColumns, displayColumnOptions]);

  return (
    <div style={{ paddingBottom: '50px' }}>
      <div className='flex-row' style={{ marginBottom: '12px' }}>
        {selectable && rowSelection?.filter(selected => !!selected).length ? (
          <>
            <div className={'flex-column list-table-selected-text-section'}>
              <span style={{ color: info.darkest }}>
                {rowSelection?.filter(selected => !!selected).length} Items selected
              </span>
            </div>
            <div className={'flex-column'}>
              {actions?.length ? (
                <Dropdown title={'Bulk Actions'}>
                  {actions?.map(({ label, onClick }) => (
                    <Button
                      variant={'link'}
                      key={label}
                      onClick={() => onClick?.({ data: currentData?.filter((row, index) => rowSelection[index]) })}
                    >
                      {label}
                    </Button>
                  ))}
                </Dropdown>
              ) : null}
            </div>
          </>
        ) : null}
        <div className={'flex-column'} style={{ flexDirection: 'row-reverse' }}>
          <Dropdown title={'Display Columns'}>
            {currentDisplayColumnOptions?.map(Header => (
              <div key={Header} style={{ paddingLeft: '8px' }}>
                <Checkbox
                  key={Header}
                  label={Header}
                  payload={{ label: Header }}
                  onChange={(e, { label }) => {
                    setCurrentDisplayColumns(oldDisplayColumns => {
                      const updatedDisplayColumns = oldDisplayColumns?.includes(label)
                        ? oldDisplayColumns?.filter(header => header !== label)
                        : [...(oldDisplayColumns || []), label];
                      onChangeDisplayColumns?.({ data: updatedDisplayColumns });
                      return updatedDisplayColumns;
                    });
                  }}
                  checked={currentDisplayColumns?.includes(Header)}
                />
              </div>
            ))}
          </Dropdown>
        </div>
      </div>
      <ReactTablev6
        loading={loading}
        className={'list-table'}
        minRows={(currentData || []).length ? 0 : 10} // this avoids UI distortion when data length is zero
        defaultPageSize={defaultPageSize}
        onPageSizeChange={newPageSize => {
          dispatch(setUserSettings({ tablePageSize: newPageSize }));
        }}
        sortable={false}
        columns={(selectable
          ? [
              {
                Header: (
                  <Checkbox
                    payload={'selectAll'}
                    checked={selectAll}
                    onChange={() => {
                      setSelectAll(oldSelectAll => {
                        setRowSelection(currentSelections =>
                          currentSelections?.map(() => (!oldSelectAll ? true : false)),
                        );
                        onRowSelection?.({
                          data: currentData.map(row => ({ ...row, selected: !oldSelectAll ? true : false })),
                        });
                        return !oldSelectAll;
                      });
                    }}
                  />
                ),
                accessor: 'checkbox',
                minWidth: 70,
              },
              ...currentColumns,
            ]
          : currentColumns
        )?.filter(({ accessor, Header }) => accessor === 'checkbox' || currentDisplayColumns?.includes(Header))}
        data={
          selectable
            ? currentData.map((row, index) => ({
                ...row,
                checkbox: (
                  <Checkbox
                    key={row.index}
                    payload={{ index, data: row }}
                    checked={rowSelection[index]}
                    onChange={(e, { index: rowIndex, data }) => {
                      setRowSelection(currentSelections =>
                        currentSelections.map((selectionState, index) => {
                          if (rowIndex === index) {
                            // if any one checkbox is being unchecked then unset selectAll
                            if (selectionState) setSelectAll(false);
                            onRowSelection?.({ data: [{ ...data, selected: !selectionState }] });
                            return !selectionState;
                          }
                          return selectionState;
                        }),
                      );
                    }}
                  />
                ),
              }))
            : currentData
        }
      />
    </div>
  );
};

const mapStateToProps = ({ userSettings }) => ({ defaultPageSize: userSettings.tablePageSize });

export default connect(mapStateToProps)(ListTable);

ListTable.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.object),
  selectable: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
      Cell: PropTypes.element,
      width: PropTypes.number,
    }),
  ),
  onRowSelection: PropTypes.func,
  displayColumns: PropTypes.arrayOf(PropTypes.string),
  displayColumnOptions: PropTypes.arrayOf(PropTypes.string),
  onChangeDisplayColumns: PropTypes.func,
};
