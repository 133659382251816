export const CANONICAL_ID_KEY = 'https://claims.cimpress.io/canonical_id';
export const ACCOUNT_ID_KEY = 'https://claims.cimpress.io/account';

export const RESOURCE_TYPE = 'production-delay';
export const READ_PRODUCTION_DELAY = 'read:production_delay';
export const UPDATE_PRODUCTION_DELAY = 'update:production_delay';
export const DELETE_PRODUCTION_DELAY = 'delete:production_delay';

export const ACCOUNT_RESOURCE_TYPE = 'account';
export const CREATE_PRODUCTION_DELAY = 'create:production_delay';
