import ServiceHttpResponseError from './serviceHttpResponseError';

function buildHeaders(auth, accept = 'application/json') {
  return new Headers({
    Accept: accept,
    Authorization: `Bearer ${auth}`,
  });
}

// helper function you can use to automagically chain promises together with some nice checkResponse syntax to handle errors
function checkResponse(serviceComment) {
  return (res) => {
    if (res.ok) {
      if (res.status === 204) {
        return res;
      }
      return res.json();
    }
    throw new ServiceHttpResponseError(`Error fetching ${serviceComment}`, res);
  };
}

const bodyMethods = ['POST', 'PUT', 'PATCH', 'DELETE'];

export function buildOptions(auth, body = null, method = 'GET', ignoreCache = true, contentType = 'application/json') {
  const headers = buildHeaders(auth, contentType);
  if (bodyMethods.includes(method)) {
    headers.append('Content-Type', contentType);
  }

  const options = {
    method,
    mode: 'cors',
    headers,
    body,
  };

  if (ignoreCache) {
    options.cache = 'no-store';
  }

  return options;
}

export function fetchRequest(endpoint, options) {
  const serviceComment = endpoint ? `service call to ${endpoint}` : null;
  return fetch(endpoint, options).then(checkResponse(serviceComment));
}
