import './Filters.css';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Checkbox } from '@cimpress/react-components';

const Filters = ({ appliedFilters = [], filterList, onChange }) => {
  const [currentFiltersState, setCurrentFiltersState] = useState(filterList);
  const [currentFiltersSelection, setCurrentFiltersSelection] = useState([]);
  useEffect(() => {
    setCurrentFiltersState(filterList);
    setCurrentFiltersSelection(
      filterList?.map(({ name, options }) => ({
        name,
        options: options.map(({ label, value }) => ({
          label,
          value,
          selected: appliedFilters
            ?.filter(f => f.name === name)[0]
            ?.options.map(option => option.label)
            .includes(label),
        })),
      })),
    );
  }, [appliedFilters, filterList]);

  return (
    <div className='filter-container'>
      <div className='filters-heading-section'>Filters</div>
      <div style={{ padding: '20px 16px' }}>Shortlist production delays based on filters.</div>
      {currentFiltersState?.map(({ name, options, element }, filterIndex) => (
        <div key={name}>
          <Accordion className='filter-accordion' title={name} defaultOpen={true}>
            {element
              ? element
              : options?.map(({ label, value }, optionIndex) => (
                  <Checkbox
                    key={label}
                    checked={currentFiltersSelection?.[filterIndex]?.options?.[optionIndex]?.selected}
                    payload={{ filterIndex, optionIndex, label, value }}
                    label={label}
                    onChange={(e, { filterIndex, optionIndex }) => {
                      setCurrentFiltersSelection(oldFiltersSelection => {
                        const newFiltersSelection = oldFiltersSelection.map(({ name, options }, i) => {
                          if (filterIndex === i) {
                            return {
                              name,
                              options: options.map((option, j) =>
                                optionIndex === j ? { ...option, selected: !option.selected } : option,
                              ),
                            };
                          }
                          return { name, options };
                        });
                        onChange?.({
                          data: newFiltersSelection
                            ?.map(({ name, options }) => ({
                              name,
                              options: options
                                .filter(({ selected }) => !!selected)
                                .map(({ label, value }) => ({ label, value })),
                            }))
                            .filter(({ options }) => options?.length),
                        });
                        return newFiltersSelection;
                      });
                    }}
                  />
                ))}
          </Accordion>
        </div>
      ))}
    </div>
  );
};

export default Filters;

Filters.propTypes = {
  appliedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ),
  filterList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ),
  onChange: PropTypes.func,
};
