import { BreadcrumbItem, Breadcrumbs, Button, Card } from '@cimpress/react-components';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { showSnackbar } from '../../redux/actions/snackbarActions';
import { Loading, ProductLinkViewerWrapper, PageNotFound, Forbidden } from '../common';
import DelayScheduleList from './DelayScheduleList';
import { getProductionDelay } from '../../services/productionDelay';
import { getProductLinks } from '../../services/productLink';
import { UPDATE_PRODUCTION_DELAY, CANONICAL_ID_KEY } from '../../constants/common';
import auth from '../../services/auth';
import RobotMessage from '../common/RobotMessage';

function ProductionDelayDetails({ accountDetails, permissions }) {
  const [isLoadingProductLinks, setIsLoadingProductLinks] = useState(false);
  const [error, setError] = useState();
  const [isLoadingDelay, setIsLoadingDelay] = useState(false);
  const [productionDelay, setProductionDelay] = useState();
  const [productLinks, setProductLinks] = useState();
  const dispatch = useDispatch();
  const { id } = useParams();
  const principal = auth.getProfile()?.[CANONICAL_ID_KEY];

  useEffect(() => {
    const fetchProductionDelay = async id => {
      setIsLoadingDelay(true);
      try {
        const productionDelayData = await getProductionDelay({ id });
        setProductionDelay(productionDelayData);
      } catch (error) {
        if (error.status === 404 || error.status === 403) {
          setError(error.status);
        }
        dispatch(
          showSnackbar({ message: `Failed to fetch Production Delay: ${JSON.stringify(error)}`, style: 'danger' }),
        );
      } finally {
        setIsLoadingDelay(false);
      }
    };
    fetchProductionDelay(id);
  }, [id, dispatch]);

  useEffect(() => {
    const fetchProductLinks = async productionDelayHref => {
      setIsLoadingProductLinks(true);
      try {
        const {
          _embedded: { item: productLinksData },
        } = await getProductLinks({
          accountId: productionDelay?.accountId,
          relatedResourceType: process.env.REACT_APP_PRODUCTION_DELAY_RESOURCE_TYPE,
          relatedResourceHref: productionDelayHref,
        });
        setProductLinks(productLinksData);
      } catch (error) {
        dispatch(showSnackbar({ message: `Failed to fetch Product Links: ${JSON.stringify(error)}`, style: 'danger' }));
      } finally {
        setIsLoadingProductLinks(false);
      }
    };
    if (productionDelay) {
      fetchProductLinks(`${process.env.REACT_APP_PRODUCTION_DELAY_BASE_URL_V1}/${id}`);
    }
  }, [productionDelay, id, dispatch]);

  return (
    <>
      {isLoadingDelay ? (
        <Loading />
      ) : error ? (
        error === 404 ? (
          <PageNotFound />
        ) : (
          <Forbidden />
        )
      ) : (
        <>
          <Breadcrumbs className='pd-breadcrumbs'>
            <BreadcrumbItem>
              <NavLink exact={true} to='/'>
                All Delays
              </NavLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <NavLink exact={true} to={`/${id}`}></NavLink>Production Delay for {productionDelay?.name}
            </BreadcrumbItem>
          </Breadcrumbs>
          <div
            className={'flex-row'}
            style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}
          >
            <div style={{ fontSize: '24px' }}>Production Delay for {productionDelay?.name}</div>
            <Link to={`/${productionDelay?.id}/edit`}>
              <Button
                disabled={!permissions.includes(UPDATE_PRODUCTION_DELAY) && productionDelay?.createdBy !== principal}
                variant='primary'
              >
                Edit
              </Button>
            </Link>
          </div>
          <div className='pd-container'>
            <Card style={{ marginBottom: '20px', padding: '8px' }}>
              <div style={{ fontSize: '18px', marginBottom: '16px' }}>Production delay details</div>
              <div style={{ fontSize: '12px', marginBottom: '20px', fontWeight: '600' }}>
                Review all delay schedules below in this production delay. Click on “edit” to add more schedules
              </div>
              <div className={'pd-drawer-heading'}>Account ID</div>
              {accountDetails.isLoading ? (
                <Loading style={{ marginLeft: '5px', justifyContent: 'left' }} size={'medium'} />
              ) : (
                <div style={{ fontSize: '14px', marginBottom: '20px', marginTop: '8px' }}>
                  {accountDetails.data.accountId} ({accountDetails.data.name})
                </div>
              )}
              <DelayScheduleList productionDelay={productionDelay} />
            </Card>
            {isLoadingProductLinks ? (
              <Loading />
            ) : (
              <Card style={{ marginBottom: '80px', padding: '8px' }}>
                <div style={{ fontSize: '18px', marginBottom: '16px' }}>Product Groups</div>

                <div style={{ fontSize: '14px', marginBottom: '16px' }}>
                  Associated product groups that contain the impacted products whose production time is extended based
                  on above delay schedules
                </div>
                {productLinks?.length ? (
                  productLinks?.map(({ productLinkHref, accountId }) => (
                    <ProductLinkViewerWrapper
                      productLinkHref={productLinkHref}
                      accountId={productionDelay?.accountId}
                      style={{ marginBottom: '24px' }}
                      key={productLinkHref}
                    />
                  ))
                ) : (
                  <RobotMessage message={'No Product Groups Linked'} />
                )}
              </Card>
            )}
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = ({ accountDetails }) => ({
  accountDetails,
});
export default connect(mapStateToProps)(ProductionDelayDetails);
