import { Modal, Button, TextField } from '@cimpress/react-components';
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showSnackbar } from '../../redux/actions/snackbarActions';
import { createProductionDelay } from '../../services/productionDelay';
import Loading from '../common/Loading';

const CreateModal = ({ modalOpen, setModalOpen, accountId }) => {
  const [isCreatingDelay, setIsCreatingDelay] = useState(false);
  const [delayName, setDelayName] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickContinue = async () => {
    setIsCreatingDelay(true);
    const productionDelay = {
      name: delayName,
      accountId,
      delaySchedule: [],
    };
    try {
      const createdProductionDelay = await createProductionDelay(productionDelay);
      dispatch(showSnackbar({ message: 'Production Delay created successfully', style: 'success', delay: 5000 }));
      history.push(`/${createdProductionDelay.id}/edit`);
    } catch (error) {
      dispatch(showSnackbar({ message: `Error creating production delay: ${JSON.stringify(error)}`, style: 'danger' }));
    } finally {
      setIsCreatingDelay(false);
    }
  };

  return (
    <>
      <Modal
        show={modalOpen}
        onRequestHide={() => setModalOpen(false)}
        title='Create Production Delay'
        closeButton={true}
        footer={
          isCreatingDelay ? null : (
            <>
              <Button onClick={() => setModalOpen(false)}>Cancel</Button>
              <Button variant='primary' onClick={onClickContinue} disabled={!delayName} style={{ marginLeft: '16px' }}>
                Continue
              </Button>
            </>
          )
        }
      >
        {isCreatingDelay ? (
          <Loading />
        ) : (
          <>
            <p>Production Delay Name</p>
            <TextField value={delayName} onChange={e => setDelayName(e.target.value)} required autoFocus={true} />
          </>
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = ({
  accountDetails: {
    data: { accountId },
  },
}) => ({
  accountId,
});
export default connect(mapStateToProps)(CreateModal);
