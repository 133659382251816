import auth from './auth';
import { FILTER_NAMES, SEARCH_FILTER_NAME, CREATED_BY_FILTER } from '../constants/filters';
import { CANONICAL_ID_KEY } from '../constants/common';
import { DELAY_SCHEDULE_STATUS } from '../constants/delaySchedule';

export const orchestrateProductionDelayData = ({ productionDelays = [], productLinks = [] }) => {
  const delayMap = {};
  productionDelays.forEach(({ id }) => {
    delayMap[id] = [];
  });
  productLinks.forEach(({ relatedResources = [], productLinkHref }) =>
    relatedResources.forEach(({ relatedResourceId }) => {
      delayMap[relatedResourceId]?.push(productLinkHref);
    }),
  );
  // merge productLinks count and production delay
  return productionDelays.map(({ id, name, delaySchedule, createdAt, createdBy, modifiedAt, modifiedBy }) => ({
    id,
    name,
    delaySchedule,
    createdAt,
    createdBy,
    modifiedAt,
    modifiedBy,
    productLinks: delayMap[id],
  }));
};

export const getScheduleStatus = ({ startDate, endDate }) => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  const currentDateObj = new Date();
  let status = 'ERROR';
  if (startDateObj <= currentDateObj && currentDateObj <= endDateObj) {
    status = DELAY_SCHEDULE_STATUS.ACTIVE;
  } else if (currentDateObj < startDateObj) {
    status = DELAY_SCHEDULE_STATUS.SCHEDULED;
  } else if (currentDateObj > endDateObj) {
    status = DELAY_SCHEDULE_STATUS.EXPIRED;
  }
  return status;
};

export const filterOrchestratedProductionDelays = ({
  masterData = [],
  currentData = [],
  filtersToApply = [],
  searchToApply: { searchText = '', searchFields = [] } = {},
}) => {
  if (searchText && searchFields?.length) filtersToApply = [...filtersToApply, { name: SEARCH_FILTER_NAME }];
  return masterData
    ?.filter(row => {
      const { delaySchedule, createdBy } = row;
      const delayScheduleStatuses = delaySchedule.map(({ status }) => status);
      const areFiltersSatisfied = filtersToApply?.every(({ name, options }) => {
        switch (name) {
          case FILTER_NAMES.STATUS: {
            if (!options?.some(({ value }) => delayScheduleStatuses.includes(value))) {
              return false;
            }
            return true;
          }
          case FILTER_NAMES.CREATED_BY: {
            if (
              !options?.some(({ value }) => {
                if (value === CREATED_BY_FILTER.OTHERS) {
                  return auth.getProfile()?.[CANONICAL_ID_KEY].toLowerCase() !== createdBy?.toLowerCase();
                }
                return value?.toLowerCase() === createdBy?.toLowerCase();
              })
            ) {
              return false;
            }
            return true;
          }
          case SEARCH_FILTER_NAME: {
            // search the text agains searchFields that are passed
            if (!searchFields.some(field => row[field]?.toLowerCase().includes(searchText.toLowerCase()))) {
              return false;
            }
            return true;
          }
          default: {
            return true;
          }
        }
      });
      return areFiltersSatisfied;
    })
    .map(row => {
      const foundRow = currentData.find(({ id }) => id === row.id);
      if (foundRow) return foundRow; // returning already visible row to preserve "selected" flag value
      return row;
    });
};
