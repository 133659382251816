import { useEffect, useState } from 'react';
import { getScheduleStatus } from '../../services/serviceHelpers';
import { Label, ReactTablev6 } from '@cimpress/react-components';
import { DELAY_SCHEDULE_STATUS, DELAY_SCHEDULE_COLOR } from '../../constants/delaySchedule';

function DelayScheduleList({ productionDelay }) {
  const [delayScheduleCuratedData, setDelayScheduleCuratedData] = useState([]);

  useEffect(() => {
    const prepareDelayScheduleCuratedData = () => {
      if (productionDelay) {
        const data = productionDelay.delaySchedule?.map(
          ({ fulfillmentLocationId, startDate, endDate, delayTime, delayUnit, delayUntil }) => {
            // standardize duration data
            let duration;
            if (delayTime) {
              duration = `${delayTime} ${delayUnit === 'HH' ? 'hour' : 'day'}${delayTime === 1 ? '' : 's'}`;
            } else {
              const delayUntilObj = new Date(delayUntil);
              duration = `Until ${delayUntilObj.toLocaleString()}`;
            }

            // calculate status of delayed schedule
            const status = getScheduleStatus({ startDate, endDate });

            return {
              fulfillmentLocationId: fulfillmentLocationId ? fulfillmentLocationId : '-',
              status,
              startDate: `${new Date(startDate).toLocaleString()}`,
              endDate: `${new Date(endDate).toLocaleString()}`,
              duration,
            };
          },
        );
        setDelayScheduleCuratedData(data);
      }
    };

    prepareDelayScheduleCuratedData();
  }, [productionDelay]);

  const columns = [
    {
      Header: 'Status',
      width: 120,
      Cell: ({ original }) => {
        switch (original.status) {
          case DELAY_SCHEDULE_STATUS.ACTIVE:
            return <Label text={original.status} status={DELAY_SCHEDULE_COLOR.ACTIVE} />;
          case DELAY_SCHEDULE_STATUS.EXPIRED:
            return <Label text={original.status} status={DELAY_SCHEDULE_COLOR.EXPIRED} />;
          case DELAY_SCHEDULE_STATUS.SCHEDULED:
            return <Label text={original.status} status={DELAY_SCHEDULE_COLOR.SCHEDULED} />;
          default:
            return <Label text={original.status} />;
        }
      },
    },
    {
      Header: 'Start Date',
      accessor: 'startDate',
    },
    {
      Header: 'End Date',
      accessor: 'endDate',
    },
    { Header: 'Duration', accessor: 'duration' },
    { Header: 'Fulfillment Location ID', accessor: 'fulfillmentLocationId' },
    // {
    //   Header: 'Actions',
    //   width: 85,
    //   Cell: ({ original }) => (
    //     <div>
    //       <Tooltip contents={'Edit'} style={{ marginRight: '12px' }}>
    //         <Button variant='anchor' onClick={() => {}}>
    //           <IconPencilAlt size={'lg'} weight={'fill'} />
    //         </Button>
    //       </Tooltip>
    //       <Tooltip contents={'Delete'}>
    //         <Button variant='anchor' onClick={() => {}}>
    //           <IconBin size={'lg'} weight={'fill'} />
    //         </Button>
    //       </Tooltip>
    //     </div>
    //   ),
    // },
  ];
  return (
    <>
      <div className={'pd-drawer-heading'} style={{ marginBottom: '8px' }}>
        Delay Schedules
      </div>
      <ReactTablev6
        columns={columns}
        data={delayScheduleCuratedData}
        showPagination={false}
        minRows={delayScheduleCuratedData.length ? delayScheduleCuratedData.length : 10}
        sortable={false}
      />
    </>
  );
}

export default DelayScheduleList;
