import { RadioGroup, Radio, Button } from '@cimpress/react-components';
import { useCallback, useState } from 'react';
import { DELAY_SCHEDULE_CARD_MODE, DELAY_SCHEDULE_TYPE } from '../../../constants/delaySchedule';
import DelayBySection from './DelayBySection';
import DelayUntilSection from './DelayUntilSection';

const DelayScheduleSection = ({ mode = DELAY_SCHEDULE_CARD_MODE.CREATE, delaySchedule = {}, onSave, onCancel }) => {
  const { DELAY_BY, DELAY_UNTIL } = DELAY_SCHEDULE_TYPE;
  const [delayType, setDelayType] = useState(delaySchedule?.delayTime ? DELAY_BY : DELAY_UNTIL);
  const [schedule, setSchedule] = useState(delaySchedule);

  const disabled = mode === DELAY_SCHEDULE_CARD_MODE.VIEW;

  const onRadioChange = (e, value) => {
    setSchedule(schedule);
    setDelayType(value);
  };

  /**
   * @param {object} schedule a production delay schedule
   * @param {string} delayType delayBy or delayUntil
   * @returns delayBy or delayUntil based schedule
   */
  const prepareSchedule = (
    { startDate, endDate, delayUnit, delayTime, delayUntil, fulfillmentLocationId },
    delayType,
  ) => ({
    startDate,
    endDate,
    fulfillmentLocationId,
    ...(delayType === DELAY_BY ? { delayUnit, delayTime } : { delayUntil }),
  });

  const onCancelEdit = () => {
    const delayTypeValue = delaySchedule?.delayTime ? DELAY_BY : DELAY_UNTIL;
    setSchedule(delaySchedule);
    setDelayType(delayTypeValue);
    onCancel?.();
  };

  const isSaveDisabled = useCallback(
    ({ delayType, schedule }) => {
      const { startDate, endDate, delayUnit, delayTime, delayUntil } = schedule;
      return !(startDate && endDate && (delayType === DELAY_BY ? delayTime && delayUnit : delayUntil));
    },
    [DELAY_BY],
  );

  return (
    <div className='delay-schedule-card'>
      <form>
        <RadioGroup
          defaultSelected={DELAY_UNTIL}
          valueSelected={delayType}
          onChange={onRadioChange}
          inline
          className='ptd-radiogroup'
        >
          <Radio
            label='Delay production until a time'
            value={DELAY_UNTIL}
            style={{ marginTop: '8px' }}
            disabled={disabled}
          />
          <Radio
            label='Add delay time to production time'
            value={DELAY_BY}
            style={{ marginTop: '8px' }}
            disabled={disabled}
          />
        </RadioGroup>
      </form>
      {delayType === DELAY_UNTIL ? (
        <DelayUntilSection schedule={schedule} setSchedule={setSchedule} disabled={disabled} />
      ) : (
        <DelayBySection schedule={schedule} setSchedule={setSchedule} disabled={disabled} />
      )}
      {mode === DELAY_SCHEDULE_CARD_MODE.VIEW ? null : (
        <div className='delay-schedule-footer'>
          {mode === DELAY_SCHEDULE_CARD_MODE.EDIT ? (
            <Button onClick={onCancelEdit}>Cancel</Button>
          ) : (
            <Button onClick={onCancel}>Cancel</Button>
          )}
          <Button
            variant='primary'
            onClick={() => onSave?.(prepareSchedule(schedule, delayType))}
            disabled={isSaveDisabled({ delayType, schedule })}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default DelayScheduleSection;
