import { combineReducers } from 'redux';
import productionDelayReducer from './ProductionDelayList';
import accountDetailsReducer from './AccountDetails';
import snackbarReducer from './Snackbar';
import userSettingsReducer from './UserSettings';

const rootReducer = combineReducers({
  productionDelay: productionDelayReducer,
  accountDetails: accountDetailsReducer,
  snackbar: snackbarReducer,
  userSettings: userSettingsReducer,
});

export default rootReducer;
