import auth from './auth';

const checkResponse = async (response = {}) => {
  if (response.ok) {
    if (response.status === 204) return;
    return await response.json();
  }
  const { status } = response;
  const { message, Message = 'something went wrong!' } = await response.json();
  return Promise.reject({ status, message: message || Message });
};

/**
 * @description combines result of all pages of an endpoint
 * @param0 {string} url
 * @param1 {object} queryParams
 * @param1 {object} headers
 * @returns combined list of resources from all the pages
 */
const getAllPages = async ({ url, queryParams, headers = {} }) => {
  const result = [];
  const config = { url, queryParams, headers };
  while (config.url) {
    const {
      _links: { next },
      _embedded: { item = [] },
    } = await getData(config);
    result.push(...item);
    config.url = next?.href;
    config.queryParams = null;
  }
  return { _embedded: { item: result } };
};

const getData = async ({ url, queryParams, headers = {} }) => {
  const response = await fetch(`${url}${queryParams ? `?${new URLSearchParams(queryParams)}` : ''}`, {
    headers: { ...headers, Authorization: `Bearer ${auth.getAccessToken()}` },
  });
  return checkResponse(response);
};

const postData = async ({ url, postBody, headers = {} }) => {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(postBody),
    headers: {
      ...headers,
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
  });
  return checkResponse(response);
};

const putData = async ({ url, putBody, headers = {} }) => {
  const response = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(putBody),
    headers: {
      ...headers,
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
  });
  return checkResponse(response);
};

const deleteData = async ({ url, deleteBody, headers = {} }) => {
  const response = await fetch(url, {
    method: 'DELETE',
    body: JSON.stringify(deleteBody),
    headers: {
      ...headers,
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${auth.getAccessToken()}`,
    },
  });
  return checkResponse(response);
};

export { getData, postData, putData, deleteData, getAllPages };
