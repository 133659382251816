import { getData } from './http';

const getLocations = () => {
  return getData({
    url: `${process.env.REACT_APP_FULFILLMENT_LOCATION_BASE_URL}fulfillmentlocations`,
    queryParams: { showArchived: false },
  });
};

const getFulfillers = () => {
  return getData({ url: `${process.env.REACT_APP_FULFILLER_IDENTITY_BASE_URL}fulfillers` });
};

const getLocationsAndFulfillersData = async () => {
  // Retrieve fulfillers data
  const fulfillers = await getFulfillers();
  const fulfillerIdToNameMap = {};

  fulfillers.forEach(({ name, fulfillerId }) => (fulfillerIdToNameMap[fulfillerId] = name));
  // Retrieve locations data
  const locations = await getLocations();
  const fulfillmentLocationData = locations.map(({ name, fulfillmentLocationId, fulfillerId }) => ({
    fulfillerName: fulfillerIdToNameMap[fulfillerId],
    fulfillmentLocationId,
    location: name,
  }));
  return fulfillmentLocationData;
};

export { getLocationsAndFulfillersData, getFulfillers, getLocations };
