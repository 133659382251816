import { searchPrincipalPermissions } from '../../../services/coam';
import {
  ACCOUNT_RESOURCE_TYPE as resourceType,
  CREATE_PRODUCTION_DELAY as permission,
} from '../../../constants/common';

const create = async ({ accountId: resourceId, principal }) => {
  try {
    const { permissions = [permission] } =
      (await searchPrincipalPermissions({
        principal,
        resourceType,
        resourceId,
        permission,
      })) || {};
    return permissions;
  } catch (err) {
    if (err.status === 404) {
      // guard needs to be silent on 404
      return [];
    }
    throw err;
  }
};

export default create;
