import { createReducer } from '@reduxjs/toolkit';
import { hideSnackbar, showSnackbar } from '../../actions/snackbarActions';

const initialState = {
  showSnackbar: false,
  message: '',
  style: 'success',
  delay: false, // setting delay to false prevents the snackbar from closing automatically.
};

const snackbarReducer = createReducer(initialState, builder => {
  builder
    .addCase(showSnackbar, (state, action) => ({
      ...state,
      showSnackbar: true,
      message: action.payload.message,
      style: action.payload.style,
      delay: action.payload.delay,
    }))
    .addCase(hideSnackbar, state => ({
      ...state,
      showSnackbar: false,
    }));
});

export default snackbarReducer;
