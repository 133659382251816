import auth from '../../services/auth';
import { ACCOUNT_ID_KEY } from '../../constants/common';
import { getAccountDetails } from '../../services/account';
import { put, takeLatest, call } from 'redux-saga/effects';
import {
  getAccountDetailsSuccess,
  getAccountDetailsFailure,
  GET_ACCOUNT_DETAILS,
} from '../actions/accountDetailsActions';
import { showSnackbar } from '../actions/snackbarActions';

// Generator function that dispatches an GET_ACCOUNT_DETAILS_SUCCESS action with data fetched from a server
export function* getAccountDetailsSaga() {
  const accountId = auth.getProfile()?.[ACCOUNT_ID_KEY];
  try {
    const data = yield call(() => getAccountDetails(accountId));
    // send orchestrated data to store
    yield put(getAccountDetailsSuccess(data));
  } catch (error) {
    console.error('Error fetching production delays:', JSON.stringify(error));
    yield put(getAccountDetailsFailure());
    yield put(showSnackbar({ message: `Failed to fetch Account Details: ${JSON.stringify(error)}`, style: 'danger' }));
  }
}

// Generator function that watches for all actions and calls the appropriate generator function
export function* watchAccountDetailsSagas() {
  yield takeLatest(GET_ACCOUNT_DETAILS, getAccountDetailsSaga);
  // make another action + saga entry here
}
