import { Card, Breadcrumbs, BreadcrumbItem, InlineEdit, Button } from '@cimpress/react-components';
import { useHistory, useParams } from 'react-router';
import ProductionDelayCard from './ProductionDelayCard';
import React, { useState, useEffect } from 'react';
import auth from '../../services/auth';
import { ExtendedProductLinkerList, RelatedResourceType } from '@cimpress-technology/extended-product-linker';
import { getProductionDelay, updateProductionDelay } from '../../services/productionDelay';
import { showSnackbar } from '../../redux/actions/snackbarActions';
import { Forbidden, Loading, PageNotFound, DeleteProductionDelayModal } from '../common';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { DELETE_PRODUCTION_DELAY, CANONICAL_ID_KEY, UPDATE_PRODUCTION_DELAY } from '../../constants/common';

const EditProductionDelay = ({ accountId, permissions }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [productionDelay, setProductionDelay] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const history = useHistory();

  const { id } = useParams();
  const dispatch = useDispatch();
  const principal = auth.getProfile()?.[CANONICAL_ID_KEY];

  useEffect(() => {
    const fetchProductionDelay = async id => {
      try {
        const ptdData = await getProductionDelay({ id });
        setProductionDelay(ptdData);
      } catch (error) {
        if (error.status === 403 || error.status === 404) {
          setError(error.status);
        }
        dispatch(
          showSnackbar({ message: `Failed to fetch ProductionDelay: ${JSON.stringify(error)}`, style: 'danger' }),
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchProductionDelay(id);
  }, [id, dispatch]);

  const onUpdateName = async ({ value }) => {
    const updateObject = {
      name: value,
      delaySchedule: productionDelay.delaySchedule,
    };
    try {
      const updatedData = await updateProductionDelay(productionDelay.id, updateObject);
      setProductionDelay(updatedData);
      dispatch(showSnackbar({ message: 'Production Delay name updated successfully', style: 'success', delay: 5000 }));
    } catch (error) {
      dispatch(
        showSnackbar({ message: `Failed to update Production Delay name: ${JSON.stringify(error)}`, style: 'danger' }),
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : error ? (
        error === 404 ? (
          <PageNotFound />
        ) : (
          <Forbidden />
        )
      ) : !(permissions.includes(UPDATE_PRODUCTION_DELAY) || productionDelay?.createdBy === principal) ? (
        <Forbidden />
      ) : (
        <>
          <Breadcrumbs className='pd-edit-breadcrumbs'>
            <BreadcrumbItem>
              <Link to='/'>All Delays</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Edit Delay</BreadcrumbItem>
          </Breadcrumbs>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <InlineEdit
              name='ptdName'
              required
              size='h2'
              value={productionDelay.name || 'Production Delay Name'}
              onSave={onUpdateName}
            />
            <div style={{ marginTop: '15px' }}>
              <Button
                disabled={!permissions.includes(DELETE_PRODUCTION_DELAY) && productionDelay?.createdBy !== principal}
                variant='default'
                style={{ marginRight: '16px' }}
                onClick={() => setOpenDeleteModal(true)}
              >
                Delete
              </Button>
              <Link to={`/${productionDelay.id}`}>
                <Button variant='primary'>View Details</Button>
              </Link>
            </div>
          </div>
          <div className='pd-container'>
            <Card className='ptd-edit-card'>
              <ProductionDelayCard productionDelay={productionDelay} setProductionDelay={setProductionDelay} />
            </Card>
            <Card className='ptd-edit-card'>
              <MemoisedExtendedProductLinkerList accountId={productionDelay?.accountId} id={id} />
            </Card>
          </div>
          <DeleteProductionDelayModal
            openModal={openDeleteModal}
            setOpenModal={setOpenDeleteModal}
            productionDelay={productionDelay}
            onSuccessfulDeletion={() => history.push('/')}
          />
        </>
      )}
    </>
  );
};

// When the productionDelays/schedules are being updated, we don't need to re-render ProductLinker section
const MemoisedExtendedProductLinkerList = React.memo(({ accountId, id }) => (
  <ExtendedProductLinkerList
    accessToken={auth.getAccessToken()}
    primaryResource={{
      type: RelatedResourceType.ProductionDelay,
      url: `${process.env.REACT_APP_PRODUCTION_DELAY_BASE_URL_V1}/${id}`,
    }}
    secondaryResource={[{ type: RelatedResourceType.ProductionDelay }]}
    authProfile={auth.getProfile()}
    account={{ id: accountId }}
  />
));

export default EditProductionDelay;
