import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Forbidden, Loading, PageNotFound } from '../common';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../redux/actions/snackbarActions';

const HandleError = ({ error }) => {
  switch (error.status) {
    case 403:
      return <Forbidden />;
    case 404:
      return <PageNotFound />;
    default:
      return null;
  }
};

/**
 *
 * @param0 { ReactComponentElement } children
 * @param1 { Function [] } guards
 * @param2 { parameters }
 * @returns children injected with permissions
 */
const AuthGuard = ({ children, guards = [], parameters = {} }) => {
  const { id } = useParams();
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    async function executeAuthGuards() {
      try {
        const results = await Promise.all(
          guards.map(guard => guard({ ...parameters, resourceId: parameters.resourceId || id })),
        );
        setIsLoading(false);
        setPermissions(results.flat());
      } catch (error) {
        setIsLoading(false);
        // in case we just want to pass permission to component
        if (!parameters.skipError) {
          setError(error);
        }
        dispatch(showSnackbar({ message: `Permissions denied: ${JSON.stringify(error)}`, style: 'danger' }));
      }
    }
    if (guards.length) {
      executeAuthGuards();
    }
  }, [guards, id, parameters, dispatch]);

  return isLoading ? (
    <Loading size={parameters.loaderSize} />
  ) : error ? (
    <HandleError error={error} />
  ) : (
    <>{React.Children.map(children, child => React.cloneElement(child, { permissions }))}</>
  );
};

export default AuthGuard;
