import { createReducer } from '@reduxjs/toolkit';
import {
  getAccountDetailsSuccess,
  getAccountDetailsFailure,
  getAccountDetails,
} from '../../actions/accountDetailsActions';

const initialState = {
  data: {},
  isLoading: false,
};
const accountDetailsReducer = createReducer(initialState, builder => {
  builder
    .addCase(getAccountDetails, state => ({ ...state, isLoading: true }))
    .addCase(getAccountDetailsSuccess, (state, action) => ({ ...state, data: action.payload, isLoading: false }))
    .addCase(getAccountDetailsFailure, state => ({ ...state, isLoading: false }))
    .addDefaultCase(state => state);
});

export default accountDetailsReducer;
