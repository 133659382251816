export const DELAY_SCHEDULE_STATUS = {
  SCHEDULED: 'Scheduled',
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
};

export const DELAY_SCHEDULE_COLOR = {
  SCHEDULED: 'info',
  ACTIVE: 'success',
  EXPIRED: 'default',
};

export const DELAY_SCHEDULE_CARD_MODE = {
  VIEW: 'view',
  EDIT: 'edit',
  CREATE: 'create',
};

export const DELAY_SCHEDULE_TYPE = {
  DELAY_BY: 'delayBy',
  DELAY_UNTIL: 'delayUntil',
};
