import { getData } from './http';

const getProductLinks = queryParams => {
  return getData({
    url: process.env.REACT_APP_PRODUCT_LINK_BASE_URL,
    queryParams,
    headers: { 'Cache-Control': 'no-cache' },
  });
};

const getProductLink = ({ id, url }) => {
  return getData({ url: url ? url : process.env.REACT_APP_PRODUCT_LINK_BASE_URL + id });
};

export { getProductLinks, getProductLink };
