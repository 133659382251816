import { createAction } from '@reduxjs/toolkit';

export const GET_PRODUCTION_DELAYS = 'GET_PRODUCTION_DELAYS';
export const GET_PRODUCTION_DELAYS_SUCCESS = 'GET_PRODUCTION_DELAYS_SUCCESS';
export const GET_PRODUCTION_DELAYS_FAILURE = 'GET_PRODUCTION_DELAY_FAILURE';

export const getProductionDelays = createAction(GET_PRODUCTION_DELAYS);
export const getProductionDelaysSuccess = createAction(GET_PRODUCTION_DELAYS_SUCCESS, payload => ({
  payload,
}));
export const getProductionDelaysFailure = createAction(GET_PRODUCTION_DELAYS_FAILURE, payload => ({
  payload,
}));
