import { IconBin, IconPencilAlt } from '@cimpress-technology/react-streamline-icons';
import { Accordion, Button, Modal } from '@cimpress/react-components';
import { teal } from '@cimpress/react-components/lib/colors';
import { useState } from 'react';
import DelayScheduleSection from './DelayScheduleSection';

const DelayScheduleAccordion = ({ delaySchedule, index, onSave, onDelete }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [mode, setMode] = useState('view');
  const [accordionOpen, setAccordionOpen] = useState(false);

  const getHeader = ({ startDate, endDate, delayTime, delayUnit, delayUntil }) => {
    let delayText;
    if (delayTime) {
      delayText = `Delay by ${delayTime} ${delayUnit === 'HH' ? 'hour' : 'day'}${delayTime === 1 ? '' : 's'} `;
    } else {
      const delayUntilObj = new Date(delayUntil);
      delayText = `Delay until ${delayUntilObj.toLocaleDateString()} `;
    }
    delayText += `for orders between ${new Date(startDate).toLocaleDateString()} and ${new Date(
      endDate,
    ).toLocaleDateString()}`;
    return delayText;
  };

  const onEditClick = () => {
    setMode('edit');
    setAccordionOpen(true);
  };

  const onCancel = () => {
    setMode('view');
  };

  const onUpdate = updatedObj => {
    onSave?.(updatedObj, index);
  };

  return (
    <>
      <Accordion
        className='ptd-accordion'
        title={<span>{getHeader(delaySchedule)}</span>}
        customOpen={accordionOpen}
        actions={
          <div style={{ display: 'flex' }}>
            {mode === 'view' ? (
              <div style={{ padding: '4px', marginRight: '12px' }}>
                <span onClick={() => onEditClick()}>
                  <IconPencilAlt color={teal.base} size='1x' />
                </span>
              </div>
            ) : null}
            <div style={{ padding: '4px' }}>
              <span onClick={() => setShowDeleteModal(true)}>
                <IconBin color={teal.base} size='1x' />
              </span>
            </div>
          </div>
        }
      >
        <DelayScheduleSection delaySchedule={delaySchedule} mode={mode} onSave={onUpdate} onCancel={onCancel} />
      </Accordion>
      <Modal
        status='danger'
        title='Confirm Deletion'
        show={showDeleteModal}
        footer={
          <>
            <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
            <Button onClick={() => onDelete?.(index)} variant='primary'>
              Yes
            </Button>
          </>
        }
      >
        <span>This Delay Schedule would be removed from the current Production Delay. Do you want to proceed ?</span>
      </Modal>
    </>
  );
};

export default DelayScheduleAccordion;
