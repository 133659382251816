import { createAction } from '@reduxjs/toolkit';

export const GET_ACCOUNT_DETAILS = 'GET_ACCOUNT_DETAILS';
export const GET_ACCOUNT_DETAILS_SUCCESS = 'GET_ACCOUNT_DETAILS_SUCCESS';
export const GET_ACCOUNT_DETAILS_FAILURE = 'GET_PRODUCTION_DELAY_FAILURE';

export const getAccountDetails = createAction(GET_ACCOUNT_DETAILS);
export const getAccountDetailsSuccess = createAction(GET_ACCOUNT_DETAILS_SUCCESS, payload => ({
  payload,
}));
export const getAccountDetailsFailure = createAction(GET_ACCOUNT_DETAILS_FAILURE, payload => ({
  payload,
}));
