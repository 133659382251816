import { Button, Drawer } from '@cimpress/react-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loading from '../common/Loading';
import DelayScheduleList from './DelayScheduleList';
import ProductLinkList from './ProductLinkList';
import AuthGuard, { readUpdateDelete } from '../Authorisation';
import { UPDATE_PRODUCTION_DELAY } from '../../constants/common';

const GuardedButtonLink = ({ productionDelay, permissions, principal }) => (
  <Link to={`/${productionDelay.id}/edit`}>
    <Button
      variant='primary'
      disabled={!permissions.includes(UPDATE_PRODUCTION_DELAY) && productionDelay.createdBy !== principal}
    >
      Edit
    </Button>
  </Link>
);

const ProductionDelayDetailsDrawer = ({ open, onClose, productionDelay, accountDetails, principal }) => {
  return (
    <Drawer
      size={0.6}
      show={open}
      onRequestHide={onClose}
      header={`Production Delay for ${productionDelay.name}`}
      closeOnClickOutside={true}
    >
      <div className='flex-row' style={{ justifyContent: 'space-between', marginBottom: '32px', alignItems: 'center' }}>
        <div>
          <div className={'pd-drawer-heading'} style={{ marginBottom: '8px' }}>
            Account ID
          </div>
          {accountDetails.isLoading ? (
            <Loading style={{ marginLeft: '5px', justifyContent: 'left' }} size={'medium'} />
          ) : (
            <div>
              {accountDetails.data.accountId} ({accountDetails.data.name})
            </div>
          )}
        </div>
        <div>
          <AuthGuard
            guards={[readUpdateDelete]}
            parameters={{ resourceId: productionDelay.id, principal, loaderSize: 'medium', skipError: true }}
          >
            <GuardedButtonLink productionDelay={productionDelay} principal={principal} />
          </AuthGuard>
        </div>
      </div>
      <DelayScheduleList productionDelay={productionDelay} />
      <ProductLinkList productionDelay={productionDelay} />
    </Drawer>
  );
};

const mapStateToProps = ({ accountDetails }) => ({ accountDetails });
export default connect(mapStateToProps)(ProductionDelayDetailsDrawer);
