import { defineMessages } from 'react-intl';

export default defineMessages({
  selectACategory: {
    id: 'categoryTreeSelect.selectACategory',
    defaultMessage: 'Select a category',
  },
  selectCategories: {
    id: 'categoryTreeSelect.selectCategories',
    defaultMessage: 'Select categories',
  },
});
