import React from 'react';
import { Card } from '@cimpress/react-components';

const LocationDetailsCard = ({ selectedLocation }) => {
  return (
    <Card header='Selected Location Details' style={{ marginBottom: '16px' }}>
      <div style={{ display: 'grid', gap: '5px', gridTemplateColumns: '1fr 1fr' }}>
        <p>
          <strong>Location</strong>
        </p>
        <p>
          <strong>Fulfillment Location ID</strong>
        </p>
      </div>
      <div style={{ display: 'grid', gap: '5px', gridTemplateColumns: '1fr 1fr' }}>
        <p>{selectedLocation.value?.location}</p>
        <p>{selectedLocation.value?.fulfillmentLocationId}</p>
      </div>
    </Card>
  );
};

export default LocationDetailsCard;
