import { createReducer } from '@reduxjs/toolkit';
import {
  getProductionDelays,
  getProductionDelaysSuccess,
  getProductionDelaysFailure,
} from '../../actions/productionDelayActions';

const initialState = {
  data: [],
  isLoading: false,
};

const productionDelayReducer = createReducer(initialState, builder => {
  builder
    .addCase(getProductionDelays, state => ({
      ...state,
      isLoading: true,
    }))
    .addCase(getProductionDelaysSuccess, (state, action) => ({
      ...state,
      data: action.payload,
      isLoading: false,
    }))
    .addCase(getProductionDelaysFailure, state => ({
      ...state,
      isLoading: false,
    }))
    .addDefaultCase(state => state);
});

export default productionDelayReducer;
