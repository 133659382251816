
import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@cimpress/react-components/lib/Alert';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import ErrorBoundary from '@products-react-components/utils/lib/ErrorBoundary';
import { fetchRequest, buildOptions } from '@products-react-components/utils/lib/serviceHelper';
import { IntlProvider } from 'react-intl';
import isEqual from 'lodash/isEqual';
import TreeSelector from '../TreeSelector';
import { REACT_APP_CATALOG_SCHEMA_SERVICE_URL, SERVICE_ERROR } from './constants';

const catalogSchemaTreeUrl = `${REACT_APP_CATALOG_SCHEMA_SERVICE_URL}/v2/tree`;

class SchemaTreeSelector extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    token: PropTypes.string.isRequired,
    onError: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    spinner: PropTypes.bool,
  };

  static defaultProps = {
    multiple: false,
    onError: () => {},
    value: null,
    spinner: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tree: null,
      error: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate = (prevProps) => {
    if (!isEqual(prevProps.token, this.props.token)) {
      this.init();
    }
  };

  init = () => {
    fetchRequest(catalogSchemaTreeUrl, buildOptions(this.props.token)).then((tree) => {
      this.setState({
        tree,
        loading: false,
        error: false,
      });
    }).catch((e) => {
      this.setState({
        loading: false,
        error: true,
      });
      this.props.onError(e);
    });
  }

  render() {
    const { spinner } = this.props;
    return (
      <ErrorBoundary>
        {
          spinner && this.state.loading
            ? <Spinner />
            : (
              <IntlProvider language="en" locale="en">
                {this.state.error
                  ? <Alert message={SERVICE_ERROR} dismissible={false} />
                  : <TreeSelector {...this.props} selectTree={this.state.tree} disabled={!spinner} />
                }
              </IntlProvider>
            )
        }
      </ErrorBoundary>
    );
  }
}

export default SchemaTreeSelector;
