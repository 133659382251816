import './ProductionDelaySearchBar.css';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@cimpress/react-components';
import IconSearch from '@cimpress-technology/react-streamline-icons/lib/IconSearch';
import CreateModal from './CreateModal';
import { CREATE_PRODUCTION_DELAY } from '../../constants/common';

const ProductionDelaySearchBar = ({ searchText, onChange, onSearch, permissions }) => {
  const [currentSearchText, setCurrentSearchText] = useState(searchText);
  const [createModalOpen, setCreateModalOpen] = useState(false);

  useEffect(() => {
    setCurrentSearchText(searchText);
  }, [searchText]);

  return (
    <div className={'flex-row production-delay-search-section'}>
      <div className={'flex-column'}>
        <TextField
          label={'Search by any keyword (production delay name, created by, modified by)'}
          value={currentSearchText}
          onChange={e => {
            if (e.isValid) {
              const value = e.target.value || '';
              setCurrentSearchText(value);
              onChange?.({ data: value });
            }
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              e.preventDefault();
              onSearch({ data: currentSearchText || '' });
            }
          }}
        />
      </div>
      <div className='flex-column' style={{ flex: 'none', paddingRight: '32px' }}>
        <Button
          className='list-screen-search-button'
          onClick={() => {
            onSearch?.({ data: currentSearchText || '' });
          }}
        >
          <IconSearch size={'2x'} weight={'light'} />
        </Button>
      </div>
      <div className={'flex-column'} style={{ flex: 'none' }}>
        <Button
          disabled={!permissions.includes(CREATE_PRODUCTION_DELAY)}
          variant={'primary'}
          style={{ height: '48px' }}
          onClick={() => setCreateModalOpen(true)}
        >
          Create Production Delay
        </Button>
        <CreateModal modalOpen={createModalOpen} setModalOpen={setCreateModalOpen} />
      </div>
    </div>
  );
};

export default ProductionDelaySearchBar;

ProductionDelaySearchBar.propTypes = {
  searchText: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
};
