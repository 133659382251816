import { Button } from '@cimpress/react-components';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import DelayScheduleSection from './DelayScheduleSection';
import './ProductionDelayCard.css';
import { useDispatch } from 'react-redux';
import DelayScheduleAccordion from './DelayScheduleAccordion';
import { useState } from 'react';
import { updateProductionDelay } from '../../services/productionDelay';
import { showSnackbar } from '../../redux/actions/snackbarActions';
import Loading from '../common/Loading';
import { DELAY_SCHEDULE_CARD_MODE } from '../../constants/delaySchedule';

const ProductionDelayCard = ({
  productionDelay,
  setProductionDelay,
  accountName,
  accountId,
  isAccountDetailsLoading,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAddSection, setShowAddSection] = useState(productionDelay?.delaySchedule?.length === 0);
  const dispatch = useDispatch();

  const onAddDelaySchedule = async delaySchedule => {
    setIsLoading(true);
    if (delaySchedule.delayUnit) {
      delaySchedule.delayTime = Number(delaySchedule.delayTime);
    }
    const updateObject = {
      name: productionDelay.name,
      delaySchedule: [...productionDelay.delaySchedule, delaySchedule],
    };
    try {
      const updatedData = await updateProductionDelay(productionDelay.id, updateObject);
      setProductionDelay(updatedData);
      dispatch(showSnackbar({ message: 'Delay Schedule added successfully', style: 'success', delay: 5000 }));
      setShowAddSection(false);
    } catch (error) {
      dispatch(showSnackbar({ message: `Failed to add Delay Schedule: ${JSON.stringify(error)}`, style: 'danger' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateDelaySchedule = async (updatedDelaySchedule, index) => {
    setIsLoading(true);
    if (updatedDelaySchedule.delayUnit) {
      updatedDelaySchedule.delayTime = Number(updatedDelaySchedule.delayTime);
    }
    const clonedProductionDelay = cloneDeep(productionDelay);
    clonedProductionDelay.delaySchedule[index] = updatedDelaySchedule;
    const updateObject = {
      name: clonedProductionDelay.name,
      delaySchedule: clonedProductionDelay.delaySchedule,
    };
    try {
      const updatedData = await updateProductionDelay(productionDelay.id, updateObject);
      setProductionDelay(updatedData);
      dispatch(showSnackbar({ message: 'Delay Schedule updated successfully', style: 'success', delay: 5000 }));
    } catch (error) {
      dispatch(showSnackbar({ message: `Failed to update Delay Schedule: ${JSON.stringify(error)}`, style: 'danger' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteDelaySchedule = async index => {
    setIsLoading(true);
    productionDelay.delaySchedule.splice(index, 1);
    const updateObject = {
      name: productionDelay.name,
      delaySchedule: [...productionDelay.delaySchedule],
    };
    try {
      const updatedData = await updateProductionDelay(productionDelay.id, updateObject);
      setProductionDelay(updatedData);
      dispatch(showSnackbar({ message: 'Delay Schedule removed successfully', style: 'success', delay: 5000 }));
      if (productionDelay?.delaySchedule?.length === 0) setShowAddSection(true);
    } catch (error) {
      dispatch(
        showSnackbar({ message: `Failed to removed Delay Schedule: ${JSON.stringify(error)}`, style: 'danger' }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div style={{ fontSize: '18px', marginBottom: '16px' }}>Production delay details</div>
          <div className={'pd-drawer-heading'}>Account ID</div>
          {isAccountDetailsLoading ? (
            <Loading style={{ marginLeft: '5px', justifyContent: 'left' }} size={'medium'} />
          ) : (
            <div style={{ fontSize: '14px', marginBottom: '20px', marginTop: '8px' }}>
              {accountId} ({accountName})
            </div>
          )}
          <div className={'pd-drawer-heading'} style={{ marginBottom: '8px' }}>
            Delay Schedules
          </div>
          {productionDelay.delaySchedule?.map((schedule, index) => (
            <DelayScheduleAccordion
              delaySchedule={schedule}
              index={index}
              onSave={onUpdateDelaySchedule}
              onDelete={onDeleteDelaySchedule}
            />
          ))}
          {showAddSection ? (
            <>
              <DelayScheduleSection
                mode={DELAY_SCHEDULE_CARD_MODE.CREATE}
                onSave={onAddDelaySchedule}
                onCancel={() => setShowAddSection(false)}
              />
            </>
          ) : (
            <Button onClick={() => setShowAddSection(true)}>Add Schedule</Button>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({
  accountDetails: {
    data: { name: accountName, accountId },
    isLoading: isAccountDetailsLoading,
  },
}) => ({
  accountName,
  accountId,
  isAccountDetailsLoading,
});
export default connect(mapStateToProps)(ProductionDelayCard);
