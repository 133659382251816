import { deleteData, getData, postData, putData, getAllPages } from './http';

const getProductionDelays = async queryParams => {
  return getAllPages({ url: process.env.REACT_APP_PRODUCTION_DELAY_BASE_URL_V1, queryParams });
};

const getProductionDelay = ({ url, id }) => {
  return getData({
    url: url ? url : `${process.env.REACT_APP_PRODUCTION_DELAY_BASE_URL_V1}/${id}`,
    headers: { 'Cache-Control': 'no-cache' },
  });
};

const createProductionDelay = postBody => {
  return postData({ url: process.env.REACT_APP_PRODUCTION_DELAY_BASE_URL_V2, postBody });
};

const updateProductionDelay = (id, putBody) => {
  const headers = { 'If-Match': '*' };
  return putData({ url: `${process.env.REACT_APP_PRODUCTION_DELAY_BASE_URL_V1}/${id}`, putBody, headers });
};

const deleteProductionDelay = id => {
  const headers = { 'If-Match': '*' };
  return deleteData({ url: `${process.env.REACT_APP_PRODUCTION_DELAY_BASE_URL_V2}/${id}`, headers });
};

export { getProductionDelays, getProductionDelay, createProductionDelay, updateProductionDelay, deleteProductionDelay };
