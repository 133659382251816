import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Loading, PageNotFound, Shell, FullStory } from './components/common';
import auth from './services/auth';
import ProductionDelayList from './components/ProductionDelayList';
import ProductionDelayDetails from './components/ProductionDelayDetails';
import EditProductionDelay from './components/EditProductionDelay';
import AuthGuard, { create, readUpdateDelete } from './components/Authorisation';
import { ACCOUNT_ID_KEY, CANONICAL_ID_KEY } from './constants/common';

auth.on('tokenExpired', () => auth.login({ nextUri: window.location.pathname, forceLogin: true }));

function App() {
  const [authenticating, setAuthenticating] = useState(false);
  const [authenticationError, setAuthenticationError] = useState();
  const { [ACCOUNT_ID_KEY]: accountId, [CANONICAL_ID_KEY]: principal } = auth.getProfile() || {};

  useEffect(() => {
    if (!auth.isLoggedIn()) {
      setAuthenticating(true);
      auth
        .ensureAuthentication({
          nextUri: window.location.pathname + window.location.search,
        })
        .then(() => setAuthenticating(false))
        .catch(err => setAuthenticationError(err));
    }
  }, []);

  return (
    <>
      {authenticating ? (
        <Loading />
      ) : auth.isLoggedIn() ? (
        <>
          <FullStory isProduction={process.env.REACT_APP_ENV === 'prd'} profile={auth.getProfile()} />
          <Router basename={process.env.REACT_APP_ROUTER_BASENAME || ''}>
            <Shell>
              <Switch>
                <Route
                  exact
                  path='/(index.html)?'
                  component={() => (
                    <AuthGuard guards={[create]} parameters={{ accountId, principal }}>
                      <ProductionDelayList />
                    </AuthGuard>
                  )}
                />
                <Route
                  exact
                  path='/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})'
                  component={() => (
                    <AuthGuard guards={[readUpdateDelete]} parameters={{ accountId, principal }}>
                      <ProductionDelayDetails />
                    </AuthGuard>
                  )}
                />
                <Route
                  exact
                  path='/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/edit'
                  component={() => (
                    <AuthGuard guards={[readUpdateDelete]} parameters={{ accountId, principal }}>
                      <EditProductionDelay />
                    </AuthGuard>
                  )}
                />
                <Route component={PageNotFound} />
              </Switch>
            </Shell>
          </Router>
        </>
      ) : authenticationError ? (
        <div>Unexpected error encountered. {authenticationError.message}</div>
      ) : null}
    </>
  );
}

export default App;
